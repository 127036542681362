/**
  *
  * START HEADER STYLES
  *
  */

/* upper header */

$headerTop:#10182d;
$newsletterIconBackground:#d9232e;
$newsletterIconColor:#d7232e;
$newsletterSubmitBorder:#b50d18;
$newsletterFormBorder:#797f8f;
$newsletterInputBorder:#d2d2d2;
$latoregular: 'latoregular';
$homeUrl:'./../../../img/frontend/';
$lowerHeaderLinks:#484848;



#upperHeader{
	background:$headerTop;
	padding:$halfPadd 0;
	.contact-icons{
		margin:0 10px 0 5px;
		.icon{
			margin-left:10px;
			margin-top:10px;
			float:left;
		}
		.facebook{
			@include square(20px);
			background:url(#{$homeUrl}facebook.png) 0px 0px no-repeat;
		}
		.twitter{
			@include rectangle(25px, 19px);
			background:url(#{$homeUrl}twitter.png) 0px 0px no-repeat;
		}
		.linkedin{
			@include square(20px);
			background:url(#{$homeUrl}linkedin.png) 0px 0px no-repeat;
		}
	}
	.link{
		@include textDecorator(white, $latoregular, 14px, 11px 0 0 0);
		text-transform:uppercase;
	}
}
.expand-newsletter{
	text-decoration:none;
	&:hover{
		cursor:pointer;
	}
	.icon{
		@include square(40px);
		background: $newsletterIconBackground url(#{$homeUrl}newsletter-icon.png) 9px 7px no-repeat;
		margin-right:10px;
	}
	.text{
		@include textDecorator(white, $latoregular, 14px, 10px 35px 0 0);
		text-transform: uppercase;
	}
	.arrow{
		margin:14px 30px 0 0;
		@include arrow($newsletterIconColor, 4px, 11px, 11px, 'bottom');
	}
}

#homeSearch{
	.input-holder{
		border:2px solid $newsletterFormBorder;
		border-right:none;
		.email{
			@include rectangle(220px, 36px);
			background:white;
			border:1px solid $newsletterInputBorder;
			border-right:none;
			border-left:2px solid $newsletterInputBorder;
		}
	}
	.submit{
		@include square(40px);
		background: $newsletterIconBackground url(#{$homeUrl}search.png) 7px 7px no-repeat;
		border:2px solid $newsletterSubmitBorder;
	}
}

/* end upper header */

/* lower header */
#lowerHeader{
	padding:23px 0 30px 0;
	border-bottom:4px solid $newsletterIconBackground;
	.navbar-collapse{
		float:right;
	}
	.navbar-nav{
		li{
			margin:0;
			a{
				@include textDecorator($lowerHeaderLinks, $latoregular, 14px, 0);
				text-decoration:none;
				text-transform:uppercase;
			}
		}
	}

}


/* end lower header */
