/** Text **/

@mixin textDecorator($color, $font, $size, $margin){
	font-family:$font;
	font-size: $size;
	color:$color;
	margin:$margin;
}

@mixin LinkDecorationNone(){
	text-decoration:none;
	&:hover, &:active, &:focus{
		text-decoration:none;
	}
}
/** Color **/

@mixin ColorFactory($color, $background, $border){
	color:$color;
	background:$background;
	border-color:$border;
}

/** Border Radius **/

@mixin BorderRadiusGeneral($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

@mixin BorderRadiusDetail($topLeft, $topRight, $bottomRight, $bottomLeft){
	-webkit-border-top-left-radius: $topLeft;
	-webkit-border-top-right-radius: $topRight;
	-webkit-border-bottom-right-radius: $bottomRight;
	-webkit-border-bottom-left-radius: $bottomLeft;

	-moz-border-radius-topleft: $topLeft;
	-moz-border-radius-topright: $topRight;
	-moz-border-radius-bottomright: $bottomRight;
	-moz-border-radius-bottomleft: $bottomLeft;

	border-top-left-radius: $topLeft;
	border-top-right-radius: $topRight;
	border-bottom-right-radius: $bottomRight;
	border-bottom-left-radius: $bottomLeft;
}

/** Placeholder **/

@mixin Placeholder($family, $size, $color){
	::-webkit-input-placeholder {
		font-size: $size;
		font-family:$family;
		color: $color;
	}

	:-moz-placeholder {
		font-size: $size;
		font-family:$family;
		color: $color;
	}

	::-moz-placeholder {
		font-size: $size;
		font-family:$family;
		color: $color;
	}

	:-ms-input-placeholder {
		font-size: $size;
		font-family:$family;
		color: $color;
	}
}

/** Inner Scrollbar **/

@mixin InnerScrollbar($width, $background, $scrollbar, $radius){

	//customizing the track
	::-webkit-scrollbar {
		width: $width;
		@include BorderRadiusGeneral($radius);
	}

	::-webkit-scrollbar-track {
		background:$background;
		@include BorderRadiusGeneral($radius);
	}

	//customizing thumb
	::-webkit-scrollbar-thumb {
		background:$scrollbar;
		@include BorderRadiusGeneral($radius);
	}
	::-webkit-scrollbar-thumb:window-inactive {
		background:$scrollbar;
		@include BorderRadiusGeneral($radius);
	}
}

/** Ellipsis **/

@mixin TextEllipsis(){
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow:hidden;
}

/** Center Container **/

@mixin CenterContainer(){
	margin-left:50%;
	@include translate(-50px,0);
}

@mixin CursorPointer(){
	&:hover{
		cursor:pointer;
	}
}

@mixin Parallax($image, $height){
	background-image: url($image);
	height: $height;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}