.SiteButtonTextAbstract{
	@include rectangle(106px, 38px);
	@include textDecorator(white, $latomedium, 14px, auto 1px auto auto);
	border-bottom-style:solid;
	border-width:2px;
	float:left;
	text-align:center;
	padding-top:9px;
	text-transform:uppercase;

}
.SiteButtonIconAbstract{
	@include rectangle(40px,38px);
	border-bottom-style:solid;
	border-width:2px;
	float:left;
	text-align:center;
	padding-top:7px;

}
.SiteButtonAbstract{
	@include rectangle(147px, 40px);
	border:none;
	padding:0;
	margin:0;
	.text{
		@extend .SiteButtonTextAbstract;
		@include Transition(background, 0.2s, ease-in-out);
	}
	.icon{
		@extend .SiteButtonIconAbstract;
		@include Transition(background, 0.2s, ease-in-out);
	}
	&:hover{
		.text{
			@include Transition(background, 0.4s, ease-in-out);
		}
		.icon{
			@include Transition(background, 0.4s, ease-in-out);
		}
	}
}

$inputBorder:#f5f5f5;
$inputText:#4e5157;

.ConvertorInput{
	@include rectangle(100px, 36px);
	@include textDecorator($inputText, $latolightitalic, 12px, 0);
	line-height:17px;
	border:1px solid $inputBorder;
	padding: 0 0 0 10px;
}

$infoTitle:#d9232e;

.RhombusTitleAbstract{
	@include textDecorator($infoTitle, $latobold, 20px, 0 0 15px 0);
	line-height: 25px;
	text-transform: uppercase;
	&:before{
		@extend .main-rhombus;
		@include square(15px!important);
		background: $infoTitle;
		content:"";
		display:inline-block;
		margin: 5px 10px 0 0;
		float:left;
	}
}

$inputHolder:#e9e9e9;
$inputBorder:#f3f3f3;
$inputLabel:#4e5157;

.FormElementAbstract{
	margin-bottom: 15px;
	.element-container{
		border:1px solid $inputHolder;
	}
	.form-label{
		@include textDecorator($inputLabel, $latoregular, 14px, 0 0 10px 0);
		line-height: 18px;
	}
	.form-input, .form-textarea{
		@include textDecorator($inputLabel, $latoregular, 14px, 0 0 0px 0);
		line-height: 18px;
		border:2px solid $inputBorder;
		padding-left:15px;
		height:38px;
	}
	.form-textarea{
		height:75px;
	}
}

@import 'buttons/Bleumarine';

$formText:#4e5157;
$homeUrl:'../../../img/';
$captchaValidationBorder:#a9a9a9;
$notReadableText:#396a9c;

.FormValidationPartAbstract{
	.validation-container{
		@include rectangle(280px, 240px);
		@include CenterContainer();
		.checkbox-container{
			@include checkbox(22px, 22px, '../../../img/landing/custom_checkbox.png');
			@include textDecorator($formText, $latoregular, 14px, 0 0 15px 0);
			line-height: 18px;
		}
		.security-message{
			@include textDecorator($formText, $latoregular, 14px, 0 0 15px 0);
			line-height: 18px;
			width:100%;
			text-align:center;
		}
		.captcha-validation{
			@include rectangle(204px, 21px);
			@include textDecorator($formText, $latoregular, 14px, 0 0 0px 0);
			@include CenterContainer();
			margin-bottom: 30px;
			line-height: 18px;
			border:1px solid $captchaValidationBorder;
			padding-left:10px;
			margin-bottom: 25px;
		}
		.not-readable {
			@include textDecorator($notReadableText, $latoregular, 14px, 0 0 15px 0);
			width:100%;
			text-align:center;
			line-height: 18px;
			display:block;
		}
	}
	.site-button{
		@include CenterContainer();
		@include CreateSiteButton();
		width:148px;
		.text{
			width:107px;
		}
		.icon{
			background-image:url(#{$homeUrl}details_secondary_icon.png) 14px 14px no-repeat;
		}
	}
}

$rhombusColor:#d9232e;
$rhombusInnerColor:#da2934;

.main-rhombus{
	@include diamondSquare($rhombusColor, 133);

}
.inner-rhombus {
	@include diamondSquare($rhombusInnerColor, 128);
	@include innerShadow(rgba(0, 0, 0, 0.4), 20px);
	@include Rotate(90deg);
	bottom: -103px;
	left: 2px;
}