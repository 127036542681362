/** start bleumarine button colors **/

$buttonTextColor:#fff;
$buttonTextBackground:#0d1939;
$buttonTextBorderColor:#040a1a;

$buttonTextBackgroundHover:#cb1d27;
$buttonTextBorderHover:#96181f;

$buttonIconColor:#fff;
$buttonIconBackground:#0d1a3d;
$buttonIconBorderColor:#040a1a;

$buttonIconBackgroundHover:#cf1f29;
$buttonIconBorderHover:#96181f;


/** end bleumarine button colors **/