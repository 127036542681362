/** Arrow **/

@mixin arrow($color, $arrowGirth, $width, $height, $position){
	display: inline-block;
	width: $width;
	height: $height;
	border-right: $arrowGirth solid $color;
	border-top: $arrowGirth solid $color;

	@if $position == "right" {
		@include Rotate(45deg);
	} @else if $position == "bottom" {
		@include Rotate(135deg);
	} @else if $position == "left" {
		@include Rotate(225deg);
	} @else if $position == "top" {
		@include Rotate(315deg);
	}
}

/** Triangle **/

@mixin triangle($color, $width, $height, $position, $type){

	/* general styles for all triangles */

	width: 0;
	height: 0;
	border-style: solid;

	/* check positions in time ascending order */
	$top : auto;
	$right: auto;
	$left: auto;
	$bottom: auto;
	@if $type == 'isosceles'{
		$left : $width / 2;
		$right : $width / 2;
		$top : $height / 2;
		$bottom : $height / 2;
	}

	@if $position == 'top'{

		$top : 0;
		$bottom : $width/2;
		border-color: transparent transparent $color transparent;

	} @else if $position == 'top-right'{

		$top : 0;
		$right : $height;
		$bottom : $width;
		$left : 0;
		border-color: transparent $color transparent transparent;

	} @else if $position == 'right'{

		$right : 0;
		$left : $height;
		border-color: transparent transparent transparent $color;

	} @else if $position == 'bottom-right' {

		$top : 0;
		$right : 0;
		$bottom : $width;
		$left:$height;
		border-color: transparent transparent $color transparent;

	} @else if $position == 'bottom' {

		$top : $width/2;
		$bottom : 0;
		border-color: $color transparent transparent transparent;

	} @else if $position == 'bottom-left' {

		$top: $width;
		$right : 0;
		$bottom : 0;
		$left: $height;
		border-color: transparent transparent transparent $color;

	} @else if $position == 'left'{

		$right : $height;
		$left : 0;
		border-color: transparent $color transparent transparent;

	} @else if $position == 'top-left'{

		$top: $width;
		$right: $height;
		border-color: $color transparent transparent transparent;

	}

	border-width: $top $right $bottom $left;
}

/** Square **/

@mixin square($width){
	display:block;
	width:$width;
	height:$width;
}

/** Rectangle **/

@mixin rectangle($width, $height){
	display: block;
	width: $width;
	height: $height;
}

/** Diamond Square **/

@mixin diamondSquare($color,$width){
	$width: sqrt((($width / 2) * ($width * 2) + ($width / 2) * ($width / 2))/2) + px;
	@include square($width);
	@include Rotate(45deg);
	background:$color;
}