@function sqrt($r) {
	$x0: 1;
	$x1: $x0;

	@for $i from 1 through 10 {
		$x1: $x0 - ($x0 * $x0 - abs($r)) / (2 * $x0);
		$x0: $x1;
	}

	@return $x1;
}
