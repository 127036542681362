/** start margins **/

$halfMargin:15px;
$fullMargin:30px;
$halfPadd:15px;
$fullPadd:30px;

/** end margins **/



