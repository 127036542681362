/** include framework dependencies **/

@import './../../framework/_Factories';
@import './../../framework/_AbstractClasses';
@import './../../framework/_Constants';

/** end include dependencies **/

/** include sections **/

$guideTitle:#0f1d41;

@import './../../sections/general/header';
@import './../../sections/general/page_header';


/** end include sections **/

.section-header{
	margin-bottom: 30px;
}
/** start members carousel section **/

.members-carousel{
	margin-bottom: 40px;
	.members-list{
		li{
			&:first-child{
				margin-left: 0;
			}
			&:last-child{
				margin-right: 0;
			}
			@include rectangle(140px, 97px);
			margin:0 $halfMargin;
			a{
				@include rectangle(100%, 100%);
				img{
					@include CenterContainer();
					margin-top:50%;
					transform:translate(-50%, -50%);
					-moz-transform:translate(-50%, -50%);
					-webkit-transform:translate(-50%, -50%);
				}
			}
		}
	}
}

$borderBottomColor:#df313c;

$buttonTextColor:#fff;
$buttonTextBackground:#d7222d;
$buttonTextBorderColor:#8e141b;

$buttonIconColor:#fff;
$buttonIconBackground:#d3202b;
$buttonIconBorderColor:#95181f;

$partnerTitle:#58585a;
$partnerDescription:#4e5157;

$partnersContainerBackground:#f3f3f3;

.partners-container{
	background:$partnersContainerBackground;
	padding-top: 40px;
	.partners-list{
		.partner{
			padding:0 $halfPadd;
			.partner-element{
				@include rectangle(100%, 350px);
				padding: $halfPadd $halfPadd $fullPadd $halfPadd;
				margin-bottom: 80px;
				background:white;
				.logo-container{
					@include rectangle(175px, 100px);
					@include CenterContainer();
					border-bottom: 2px solid $borderBottomColor;
					img{
						@include CenterContainer();
						margin-top:50%;
						transform:translate(-50%, -100%);
						-moz-transform:translate(-50%, -100%);
						-webkit-transform:translate(-50%, -100%);
					}
				}
				.info-container{
					h3{
						width: 100%;
						text-align: center;
						margin-bottom: $halfMargin;
						a{
							@include textDecorator($partnerTitle, $latobold, 20px, 0 0 0 0);
							@include LinkDecorationNone();
							line-height:24px;
						}
					}
					p{
						@include textDecorator($partnerDescription, $latoregular, 14px, 0 0 $fullMargin 0);
						line-height:18px;
						width: 100%;
						text-align: center;
					}
					.site-button{
						@extend .SiteButtonAbstract;
						@include CenterContainer();
						.text{
							@include ColorFactory($buttonTextColor, $buttonTextBackground, $buttonTextBorderColor);
						}
						.icon{
							@include ColorFactory($buttonIconColor, $buttonIconBackground, $buttonIconBorderColor);
							background:$buttonIconBackground url(#{$homeUrl}nearshoring_icon.png) 11px 9px no-repeat;

						}
					}
				}
			}
		}
	}
}

/** end members carousel section **/

@media(max-width:1023px){
	.row, .centered{
		width:100%;
	}
}