/** Checkbox **/

@mixin checkbox($width, $height, $url){
	input[type=checkbox].custom-checkbox, input[type=checkbox].custom-checkbox{
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rectangle(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}

	input[type=checkbox].custom-checkbox + label.custom-label, input[type=checkbox].custom-checkbox + label.custom-label {
		padding-left:$width + 5px;
		height:$height;
		display:inline-block;
		line-height:$height;
		background-repeat:no-repeat;
		background-position: 0 0;
		vertical-align:middle;
		cursor:pointer;
	}

	input[type=checkbox].custom-checkbox:checked + label.custom-label, input[type=checkbox].custom-checkbox:checked + label.custom-label {
		background-position: 0 (-$height);
	}

	label.custom-label {
		background-image:url($url);
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		padding-top:2px;
	}
}

/** Radio **/

@mixin radio($width, $height, $url){
	input[type=radio].custom-radio, input[type=radio].custom-radio{
		position:absolute;
		z-index:-1000;
		left:-1000px;
		overflow: hidden;
		clip: rectangle(0 0 0 0);
		height:1px;
		width:1px;
		margin:-1px;
		padding:0;
		border:0;
	}

	input[type=radio].custom-radio + label.custom-label, input[type=radio].custom-radio + label.custom-label {
		padding-left:$width + 5px;
		height:$height;
		display:inline-block;
		line-height:$height;
		background-repeat:no-repeat;
		background-position: 0 0;
		vertical-align:middle;
		cursor:pointer;
	}

	input[type=radio].custom-radio:checked + label.custom-label, input[type=radio].custom-radio:checked + label.custom-label {
		background-position: 0 (-$height);
	}
	label.custom-label {
		background-image:url($url);
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		padding-top:2px;
	}
}

